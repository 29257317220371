import React, { useEffect, useRef, useState } from 'react'
import { getDevident, getStockDetails } from '../../../../services/TradeService'
import { createChart, ColorType } from 'lightweight-charts';
import moment from 'moment/moment'
import { Link } from 'react-router-dom';
import LineChart from '../charts/LineChart';
import BarChart from '../charts/BarChart';
import { Button } from 'react-bootstrap';

const StockOverview = (props) => {
	const [ChartData, setChartData] = useState([])
	const [changeChart, setChangeChart] = useState(true)
	const [dividendData, setDividenddata] = useState([])
	const [chartType, setChartType] = useState('2')
	const {stockData, stockDate, setStockdate, tradeCode, tradeP
	} = props;


	  const [localAskPrice, setLocalAskPrice] = useState("");
    const [localBidPrice, setLocalBidPrice] = useState("");

    const callLocal = async () => {
      const localAskPrice = JSON.parse(localStorage?.getItem("askPrice"));
      const localBidPrice = JSON.parse(localStorage?.getItem("bidPrice"));
      setLocalAskPrice(localAskPrice || 0);
      setLocalBidPrice(localBidPrice || 0);
    };

    useEffect(() => {
      // Call local prices initially
      callLocal();

      // Set up an interval to check for updates
      const interval = setInterval(callLocal, 1000); // Check every second

      return () => clearInterval(interval); // Cleanup on unmount
    }, []);

	const [allData, setAllData] = useState({ open: 0, high: 0 })
	const { open, high } = allData

	// chart fun


	// allData handle
	const averageData = () => {
		let a = 0
		for (let i = 0; i < stockData?.length; i++) {

			if (a < stockData[i]?.high) {
				a = stockData[i]?.high
			}
		}
		setAllData({ ...allData, high: a })
	}
	useEffect(() => {
		averageData()
		// setAllData({ ...allData, open: stockData[stockData?.length - 1]?.open })
	}, [stockData])

	const handleSelectDate = (n) => {
		setStockdate(n)
	}
	const [cryptoName, setCryptoName] = useState('')
	useEffect(() => {
		let params = {
			tradeCode: tradeCode,
			interval: '1Y'
		}
		if (tradeCode !== undefined && tradeCode !== '') {
			try {
				getDevident(params).then((res) => {
					if (res?.status === 200) {
						setDividenddata(res.data)

					}
				})
			} catch (error) {

			}
		}
		const n = tradeCode.split('_')
		let cypName= n[n?.length - 2];
		setCryptoName(cypName)
	}, [tradeCode])
	return (
    <>
      {/* <div class="tab-pane fade show active" id="pills-overview" role="tabpanel"
				aria-labelledby="pills-home-tab"> */}

      <div class="overview-sec">
        {/* <div class="form-check form-switch mb-3">
						<input class="form-check-input toggle-chart" onClick={() => changeChart === true ? setChangeChart(false) : setChangeChart(true)} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
						<label class="form-check-label" for="flexSwitchCheckDefault">{changeChart === true ?'Switch to Bar Chart':'Switch to Bar Chart'}</label>
					</div> */}
        {/* <Button onClick={() => changeChart === true ? setChangeChart(false) : setChangeChart(true)}>change</Button> */}
        <div class="tread-vol-bx  border-bottom">
          <ul class="nav nav-pills d-flex" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="pills-home-tab"
                data-bs-toggle="pill"
                onClick={() => setChartType("2")}
                data-bs-target="#lineChart"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
              >
                Line Chart
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#barChart"
                onClick={() => setChartType("1")}
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                Candle Chart
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#barChart"
                onClick={() => setChartType("0")}
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                Bar Chart
              </button>
            </li>
          </ul>
          <div class="col-sm-12">
            <div>
              {/* {
								changeChart === true ? <LineChart data={data} /> : <BarChart dataBar={dataBar} />
							} */}
              {/* <BarChart/> */}
              <LineChart chartType={chartType} symbol={cryptoName} />
              {/* <img src="assets/image/grp.jpg" class="img-fluid"/> */}
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-12">
        <div class="data-min-bx">
          <div className="row">
            <h4 className="col-3 white_text">Performance</h4>
            <h5 className="col-4 white_text" style={{ "padding-top": "8px" }}>
              Charts by:{" "}
              <Link
                to={`https://www.tradingview.com/`}
                target="_blank"
                className="candle-chart-link"
              >
                tradingview
              </Link>
            </h5>

            <div class="row text-center col-5">
              <div class="col-sm-12 ">
                <ul class="slec-dta-ch">
                  {/* <li className={stockDate === '30' && 'day-active white'} onClick={() => handleSelectDate('30')}><Link className={stockDate === '30' && 'day-active white'}>30M</Link></li>
                        <li className={stockDate === '1' && 'day-active white'} onClick={() => handleSelectDate('1')}><Link className={stockDate === '1' && 'day-active white'}>1m</Link></li>
                        <li className={stockDate === '5' && 'day-active white'} onClick={() => handleSelectDate('5')}><Link className={stockDate === '5' && 'day-active white'}>5M</Link></li> */}
                  {/* <li className={stockDate === '60' && 'day-active white'} onClick={() => handleSelectDate('60')}><Link className={stockDate === '60' && 'day-active white'}>1H</Link></li> */}
                  {/* <li className={stockDate === '1H' && 'day-active white'} onClick={() => handleSelectDate('1H')}><Link className={stockDate === '1H' && 'day-active white'}>1H</Link></li>
										<li className={stockDate === '1D' && 'day-active white'} onClick={() => handleSelectDate('1D')}><Link className={stockDate === '1D' && 'day-active white'}>1D</Link></li>
										<li className={stockDate === '1W' && 'day-active white'} onClick={() => handleSelectDate('1W')}><Link className={stockDate === '1W' && 'day-active white'}>1W</Link></li>
										<li className={stockDate === '1M' && 'day-active white'} onClick={() => handleSelectDate('1M')}><Link className={stockDate === '1M' && 'day-active white'}>1M</Link></li>
										<li className={stockDate === '12M' && 'day-active white'} onClick={() => handleSelectDate('12M')}><Link className={stockDate === '12M' && 'day-active white'}>1Y</Link></li> */}
                  {/* <li><Link onclick={handleSelectDate}>max</Link></li>
									<li><Link onclick={handleSelectDate}>Sip</Link></li> */}
                </ul>
              </div>
            </div>
          </div>
          <div class="row white_text">
            <div class="col-sm-4">
              <div class="pr-mbx mt-4 mb-4">
                <h5>ASK Price</h5>
                <span>{tradeP?.ask_price || localAskPrice || 0}</span>
                <span class="pin-x">
                  <i class="bi bi-tag"></i>
                </span>
              </div>
            </div>

            <div class="col-sm-4">
              <div class="pr-mbx mt-4 mb-4">
                <h5>BID Price</h5>
                <span>{tradeP?.bid_price || localBidPrice || 0}</span>
                <span class="pin-x">
                  <i class="bi bi-tag"></i>
                </span>
              </div>
            </div>

            <div class="col-sm-4">
              <div class="pr-mbx mt-4 mb-4">
                <h5>Last {stockData[0]?.last_trade?.taker_side}</h5>
                <span>{stockData[0]?.last_trade?.price || "NA"}</span>
                <span class="pin-x">
                  <i class="bi bi-tag"></i>
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* <div className='col-sm-12'>
					<div className="modal-header p-0 border-bottom-0">
						<h5 className="modal-title h4" id="exampleModalLgLabel">Dividend</h5>
					</div>
					<table class="table table-input1 table-bordered mt-3 text-center">
						<thead>
							<tr>
								<th scope="col">Cash Dividend</th>
								<th scope="col">Dividend/Crypto</th>
								<th scope="col">Ex Date</th>
							</tr>
						</thead>
						<tbody>
							{
								dividendData.map((val, ind) => {
									return (
										<tr key={ind}>
											<td>Final</td>
											<td>$ {val.amount}</td>
											<td>{moment(val.exDate).format('DD MMM YYYY')}</td>
										</tr>
									)
								})
							}
						</tbody>
					</table>
				</div> */}

        {/* <div class="col-sm-12">
					<div class="analyst-bx">

						<h4 class="mt-4 mb-3">Analyst Estimates</h4>

						<div class="row mb-4">
							<div class="col-sm-2 mt-4">
								<div class="apt-bx">
									<span>45%</span>
								</div>
							</div>

							<div class="col-sm-7 mb-4">
								<div class="row mt-4">
									<label for="inputEmail3"
										class="col-sm-1 col-form-label">Buy</label>
									<div class="col-sm-9 mt-3">
										<div class="progress">
											<div class="progress-bar" role="progressbar"
												style={{ "width": "90%;" }} aria-valuenow="25"
												aria-valuemin="0" aria-valuemax="100"></div>
										</div>
									</div>
									<label for="inputEmail3"
										class="col-sm-1 col-form-label text-end">43%</label>
								</div>

								<div class="row mt-4">
									<label for="inputEmail3"
										class="col-sm-1 col-form-label">Hold</label>
									<div class="col-sm-4 mt-3">
										<div class="progress">
											<div class="progress-bar" role="progressbar"
												style={{ "width": "14%;" }} aria-valuenow="25"
												aria-valuemin="0" aria-valuemax="100"></div>
										</div>
									</div>
									<label for="inputEmail3"
										class="col-sm-1 col-form-label text-end">14%</label>
								</div>


								<div class="row mt-4">
									<label for="inputEmail3"
										class="col-sm-1 col-form-label">Sell</label>
									<div class="col-sm-5 mt-3">
										<div class="progress">
											<div class="progress-bar" role="progressbar"
												style={{ "width": "25%;" }} aria-valuenow="25"
												aria-valuemin="0" aria-valuemax="100"></div>
										</div>
									</div>
									<label for="inputEmail3"
										class="col-sm-1 col-form-label text-end">43%</label>
								</div>

							</div>
						</div>


						<div class="row mt-4">
							<div class="col-sm-6">
								<div class="Fundamentals-bx">
									<h5 class="mb-3">Tata Power Co Ltd Key Indicators</h5>
									<div class="table-responsive">
										<div class="table-input1">
											<table class="table ">
												<tbody>
													<tr>
														<th scope="row">Market Cap</th>
														<td class="text-end">₹61,590Cr</td>
													</tr>
													<tr>
														<th scope="row">P/B Ratio</th>
														<td class="text-end">11.38 </td>
													</tr>
													<tr>
														<th scope="row">ROCE</th>
														<td class="text-end">2.75</td>
													</tr>

													<tr>
														<th scope="row">Dividend Yield</th>
														<td class="text-end">2.75</td>
													</tr>

													<tr>
														<th scope="row">Market Cap</th>
														<td class="text-end">2.75</td>
													</tr>
												</tbody>

											</table>
										</div>
									</div>
								</div>
							</div>

							<div class="col-sm-6">
								<div class="Fundamentals-bx">
									<h5 class="mb-3">Revenue Statement</h5>
									<div class="table-responsive">
										<div class="table-input1">
											<table class="table ">
												<tbody>
													<tr>
														<th scope="row">Market Cap</th>
														<td class="text-end">₹61,590Cr</td>
													</tr>
													<tr>
														<th scope="row">P/B Ratio</th>
														<td class="text-end">11.38 </td>
													</tr>
													<tr>
														<th scope="row">ROCE</th>
														<td class="text-end">2.75</td>
													</tr>

													<tr>
														<th scope="row">Dividend Yield</th>
														<td class="text-end">2.75</td>
													</tr>

													<tr>
														<th scope="row">Market Cap</th>
														<td class="text-end">2.75</td>
													</tr>
												</tbody>

											</table>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>


				<div class="col-sm-12 mt-4">

					<h4>Financials</h4>


					<div class="row mt-4">

						<div class="col-sm-12">
							<ul class="nav nav-justified nav-pills mb-3" id="pills-tab"
								role="tablist">
								<li class="nav-item" role="presentation">
									<button class="nav-link active" id="pills-revenue-tab"
										data-bs-toggle="pill" data-bs-target="#pills-revenue"
										type="button" role="tab" aria-controls="pills-revenue"
										aria-selected="true">Revenue</button>
								</li>
								<li class="nav-item" role="presentation">
									<button class="nav-link" id="pills-profile-tab"
										data-bs-toggle="pill" data-bs-target="#pills-profile"
										type="button" role="tab" aria-controls="pills-profile"
										aria-selected="false">Profit</button>
								</li>
								<li class="nav-item" role="presentation">
									<button class="nav-link" id="pills-contact-tab"
										data-bs-toggle="pill" data-bs-target="#pills-contact"
										type="button" role="tab" aria-controls="pills-contact"
										aria-selected="false">Net Worth</button>
								</li>
							</ul>

						</div>

					</div>



					<div class="col-sm-12 mt-3 mb-4">
						<div class="financials-bx">
							<div class="tab-content" id="pills-tabContent">
								<div class="tab-pane fade show active" id="pills-revenue"
									role="tabpanel" aria-labelledby="pills-revenue-tab">
									<div class="fincal-bx">
										<img src="assets/image/gp.png" class="img-fluid" />
									</div>
								</div>

								<div class="tab-pane fade" id="pills-profile" role="tabpanel"
									aria-labelledby="pills-profile-tab">
									<div class="fincal-bx" >
										<img src="assets/image/chect.png" class="img-fluid" />
									</div>
								</div>


								<div class="tab-pane fade" id="pills-contact" role="tabpanel"
									aria-labelledby="pills-contact-tab">
									<div class="fincal-bx">
										<img src="assets/image/chect-2.png" class="img-fluid" />
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>


				<div class="col-sm-12 mt-5">

					<h4>About Tata Power</h4>


					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
						imperdiet, nulla et dictum interdum, nisi lorem egestas vitae scel<span
							id="dots">...</span>erisque enim ligula venenatis dolor. Maecenas
						nisl est, ultrices nec congue eget, auctor vitae massa. Fusce luctus
						vestibulum augue ut aliquet. Nunc sagittis dictum nisi,<span id="more">
							sed ullamcorper ipsum dignissim ac. In at libero sed nunc venenatis
							imperdiet sed ornare turpis. Donec vitae dui eget tellus gravida
							venenatis. Integer fringilla congue eros non fermentum. Sed dapibus
							pulvinar nibh tempor porta.</span></p>
					<button onclick="myFunction()" id="myBtn" class="btn mb-3">Read
						more</button>

					<div class="row">
						<div class="col-sm-6">
							<div class="table-responsive">
								<div class="table-input1">
									<table class="table ">
										<tbody>
											<tr>
												<th scope="row">Parent Organisation</th>
												<td class="text-end">The Tata Power Company
													Limited</td>
											</tr>
											<tr>
												<th scope="row">Founded</th>
												<td class="text-end">1919</td>
											</tr>
										</tbody>

									</table>
								</div>
							</div>
						</div>


						<div class="col-sm-6">
							<div class="table-responsive">
								<div class="table-input1">
									<table class="table ">
										<tbody>
											<tr>
												<th scope="row">Parent Organisation</th>
												<td class="text-end">The Tata Power Company
													Limited</td>
											</tr>
											<tr>
												<th scope="row">Founded</th>
												<td class="text-end">1919</td>
											</tr>
										</tbody>

									</table>
								</div>
							</div>
						</div>

					</div>

				</div>


				<div class="col-sm-12 mt-3">

					<h4 class="mb-3">Shareholding Pattern</h4>

					<div class="row">
						<div class="col-sm-12 shareholding-bx">

							<ul class="slec-dta-ch sh-ul">
								<li><a href="">Dec '21</a></li>
								<li><a href="">Dec '21</a></li>
								<li><a href="">Dec '21</a></li>
								<li><a href="">Dec '21</a></li>
								<li><a href="">Dec '21</a></li>

							</ul>


							<div class="Fundamentals-bx mt-5">

								<div class="table-responsive">
									<div class="table-input1">
										<table class="table ">
											<tbody>

												<tr>
													<th scope="row">
														<div class="row mt-4">
															<label for="inputEmail3"
																class="col-sm-10 col-form-label">Promoters</label>
															<div class="col-sm-8 mt-3">
																<div class="progress">
																	<div class="progress-bar"
																		role="progressbar"
																		style={{ "width": "60%;" }}
																		aria-valuenow="25"
																		aria-valuemin="0"
																		aria-valuemax="100">
																	</div>
																</div>
															</div>
															<label for="inputEmail3"
																class="col-sm-2 col-form-label text-end">43%</label>
														</div>
													</th>

												</tr>

												<tr>
													<th scope="row">
														<div class="row mt-4">
															<label for="inputEmail3"
																class="col-sm-10 col-form-label">Retail
																And Others</label>
															<div class="col-sm-8 mt-3">
																<div class="progress">
																	<div class="progress-bar"
																		role="progressbar"
																		style={{ "width": "80%;" }}
																		aria-valuenow="25"
																		aria-valuemin="0"
																		aria-valuemax="100">
																	</div>
																</div>
															</div>
															<label for="inputEmail3"
																class="col-sm-2 col-form-label text-end">43%</label>
														</div>
													</th>

												</tr>

												<tr>
													<th scope="row">
														<div class="row mt-4">
															<label for="inputEmail3"
																class="col-sm-10 col-form-label">Other
																Domestic Institutions</label>
															<div class="col-sm-8 mt-3">
																<div class="progress">
																	<div class="progress-bar"
																		role="progressbar"
																		style={{ "width": "90%;" }}
																		aria-valuenow="25"
																		aria-valuemin="0"
																		aria-valuemax="100">
																	</div>
																</div>
															</div>
															<label for="inputEmail3"
																class="col-sm-2 col-form-label text-end">43%</label>
														</div>
													</th>

												</tr>


											</tbody>

										</table>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>


				<div class="col-sm-12 mt-4 mb-4">

					<h4>Top Mutual</h4>

					<div class="col-sm-12">
						<div class="Fundamentals-bx">
							<h5 class="mb-3">Revenue Statement</h5>
							<div class="table-responsive">
								<div class="table-input1">
									<table class="table ">
										<tbody>
											<tr>
												<th scope="row">Market Cap</th>
												<td class="text-end">₹61,590Cr</td>
											</tr>
											<tr>
												<th scope="row">P/B Ratio</th>
												<td class="text-end">11.38 </td>
											</tr>
											<tr>
												<th scope="row">ROCE</th>
												<td class="text-end">2.75</td>
											</tr>

											<tr>
												<th scope="row">Dividend Yield</th>
												<td class="text-end">2.75</td>
											</tr>

											<tr>
												<th scope="row">Market Cap</th>
												<td class="text-end">2.75</td>
											</tr>
										</tbody>

									</table>
								</div>
							</div>
						</div>
					</div>

				</div> */}
      </div>

      {/* </div> */}
    </>
  );
}
export default StockOverview